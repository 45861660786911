

































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SystemSettingInfoProperties } from './SystemSettingInfoView.vue';
import AutoNumeric, { Options } from 'autonumeric';
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

@Component({})
export default class SystemSettingMiscellaneousView extends Vue {
    @Prop() private properties: SystemSettingInfoProperties;
    private inputCustomerTokenExpires: HTMLInputElement;

    public get dateFormatOptions() {
        return [
            {
                text: "d/M/yyyy",
                value: "d/M/yyyy"
            },
            {
                text: "d/M/yy",
                value: "d/M/yy"
            },
            {
                text: "dd/MM/yyyy",
                value: "dd/MM/yyyy"
            },
            {
                text: "dd/MM/yy",
                value: "dd/MM/yy"
            },
            {
                text: "M/d/yyyy",
                value: "M/d/yyyy"
            },
            {
                text: "M/d/yy",
                value: "M/d/yy"
            },
            {
                text: "MM/dd/yyyy",
                value: "MM/dd/yyyy"
            },
            {
                text: "MM/dd/yy",
                value: "MM/dd/yy"
            },
            {
                text: "yyyy/M/d",
                value: "yyyy/M/d"
            },
            {
                text: "yyyy/MM/dd",
                value: "yyyy/MM/dd"
            }
        ];
    }

    public get timeFormatOptions() {
        return [
            {
                text: "H:mm:ss",
                value: "H:mm:ss"
            },
            {
                text: "HH:mm:ss",
                value: "HH:mm:ss"
            },
            {
                text: "h:mm:ss tt",
                value: "h:mm:ss a"
            },
            {
                text: "hh:mm:ss tt",
                value: "hh:mm:ss a"
            },
            {
                text: "H:mm",
                value: "H:mm"
            },
            {
                text: "HH:mm",
                value: "HH:mm"
            },
            {
                text: "h:mm tt",
                value: "h:mm a"
            },
            {
                text: "hh:mm tt",
                value: "hh:mm a"
            }
        ];
    }

    public get deliveryMethodOptinos() {
        return [
            {
                text: this.$t("text.standard-delivery"),
                value: "Standard-Delivery"
            },
            {
                text: this.$t("text.on-hold-delivery"),
                value: "On-Hold-Delivery"
            },
            {
                text: this.$t("text.store-pickup"),
                value: "Store-Pickup"
            }
        ];
    }

    public get systemSetting() {
        return this.properties.systemSetting;
    }

    public get miscellaneous() {
        if (!this.systemSetting.miscellaneous) {
            this.systemSetting.miscellaneous = {
                customerTokenExpires: null
            };
        }
        return this.systemSetting.miscellaneous;
    }

    public get allowedDeliveryMethods() {
        return this.miscellaneous.allowedDeliveryMethods ?? [];
    }

    public set allowedDeliveryMethods(v: any) {
        this.miscellaneous.allowedDeliveryMethods = v ?? [];
    }

    public get customerTokenExpires() {
        if (this.customerTokenExpiresUsed) {
            return this.properties.customerTokenExpires;
        } else {
            return this.$t('text.never');
        }
    }

    public set customerTokenExpires(v: any) {
        this.properties.customerTokenExpires = v;
    }

    public get customerTokenExpiresUsed() {
        return this.properties.customerTokenExpiresUsed;
    }

    public set customerTokenExpiresUsed(v: boolean) {
        this.properties.customerTokenExpiresUsed = v;
    }

    public customerTokenExpiresUsedChanged() {
        if (this.customerTokenExpiresUsed) {
            this.customerTokenExpires = 1;
            this.updateFormatter();
        } else {
            AutoNumericUtil.remove(this.inputCustomerTokenExpires);
        }
    }

    public customerTokenExpiresBlur() { 
        const n1 = AutoNumericUtil.find(this.inputCustomerTokenExpires);
        if (n1 && n1.getNumber() <= 0) {
            this.customerTokenExpires = 1;
            n1.set(1);
        }
    }

    public mounted() {
        this.properties.events.subscribe('update-formatter', this.updateFormatter);
        this.inputCustomerTokenExpires = this.$el.querySelector('#customer-token-expires');

        this.updateFormatter();
    }

    public destroyed() {
        this.properties.events.remove('update-formatter', this.updateFormatter);
    }

    public updateFormatter() {
        setTimeout(() => {
            if (this.miscellaneous) {
                const options: Options = {
                    minimumValue: "0",
                    historySize: 0,
                    decimalPlaces: 0,
                    outputFormat: "number",
                    digitGroupSeparator: "",
                    unformatOnHover: false,
                    unformatOnSubmit: false
                };

                if (this.customerTokenExpiresUsed) {
                    AutoNumericUtil.findOrNew(this.inputCustomerTokenExpires, null, options)
                        .set(this.customerTokenExpires);
                }
            }
        }, 150);
    }
}
